<template>
  <li class="relative border-b border-grey-header px-3 py-2 h-10.5rem border-r" :class="{ 'bg-neutral-100': !day.isCurrentMonth }">
    <div class="flex justify-between mb-2">
      <div class="font-semibold py-3px px-6px" :class="{ 'rounded-full bg-yellow h-7 w-7 text-white': isToday && day.isCurrentMonth, 'text-neutral-300': !day.isCurrentMonth }">
        {{ labelDate }}
      </div>
      <Warning color="yellow" width="20" v-if="isConflict" />
    </div>
    <div v-show="index <= 2" v-for="(schedule, index) in day.schedule" :key="index" class="flex justify-between cursor-pointer text-xs border-l-2 border-yellow mb-2 bg-yellow-min p-1">
      <div class="truncate text-xs" @click="clickSchedule(schedule, isScheduleConflict(schedule.startTime))">
        <span class="w-2/5 font-medium">{{ timeFormating(schedule.startTime, 'HH:mm') }}</span> <span class="font-bold w-full">{{ schedule.lmsClassScheduleName }}</span>
      </div>
      <div class="my-auto pl-1" v-if="isScheduleConflict(schedule.startTime)">
        <Warning color="yellow" width="13" height="13" />
      </div>
    </div>
    <div @click="clickMore()" v-if="day?.schedule?.length >= 4" class="text-xs font-bold cursor-pointer hover:text-yellow">+ {{ day.schedule.length - 3 || 0 }} More</div>
    <div v-if="day?.schedule?.length === 0 && day.isCurrentMonth" class="h-28"></div>
    <div
      class="absolute z-10 top-0 w-60 bg-white shadow-card p-3"
      :class="{ 'right-0': rightContentIndex.includes(indexDay), '-top-24': bottomContentIndex.includes(indexDay) }"
      v-if="day.isShowDetails"
    >
      <div class="flex justify-between cursor-pointer" @click="closeDetails()">
        <div class="font-bold">{{ dayName }}</div>
        <div><Close /></div>
      </div>
      <div
        @click="clickSchedule(schedule, isScheduleConflict(schedule.startTime))"
        v-for="(schedule, index) in day.schedule"
        :key="index"
        class="flex justify-between cursor-pointer text-xs border-l-2 border-yellow mb-1 mt-2 bg-yellow-min p-1"
      >
        <div class="truncate text-xs" @click="clickSchedule(schedule, isScheduleConflict(schedule.startTime))">
          <span class="w-2/5 font-medium">{{ timeFormating(schedule.startTime, 'HH:mm') }}</span> <span class="font-bold w-full">{{ schedule.lmsClassScheduleName }}</span>
        </div>
        <div class="my-auto pl-1" v-if="isScheduleConflict(schedule.startTime)">
          <Warning color="yellow" width="13" height="13" />
        </div>
      </div>
    </div>
  </li>
</template>
  
  <script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  name: 'DayItem',
  components: {
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Close'),
    Warning: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Warning')
  },
  props: {
    day: {
      type: Object,
      required: true
    },
    isToday: {
      type: Boolean,
      default: false
    },
    indexDay: {
      type: Number,
      default: 0
    },
    dateFromQuery: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      rightContentIndex: [6, 13, 20, 27, 34],
      bottomContentIndex: [28, 29, 30, 31, 32, 33, 34]
    }
  },

  created() {},

  computed: {
    labelDate() {
      return dayjs(this.day.date).format('D')
    },
    dayName() {
      return dayjs(this.day.date).format('ddd, D MMM')
    },
    selectedDateFromQuery() {
      if (this.dateFromQuery) return dayjs(this.dateFromQuery).format('YYYY-MM-DD')
      return null
    },
    selectedTimeFromQuery() {
      if (this.dateFromQuery) return dayjs(this.dateFromQuery).format('HH:mm')
      return null
    },
    isConflict() {
      if (this.selectedDateFromQuery === this.day.date) return true
      return false
    }
  },
  methods: {
    backgroundColorSelected() {},
    timeFormating(day, format) {
      const localTime = dayjs.utc(day).local().format(format)
      return localTime
    },
    isScheduleConflict(currentTime) {
      return this.timeFormating(currentTime, 'HH:mm') === this.selectedTimeFromQuery
    },
    clickMore() {
      this.$emit('clickMore', this.indexDay, true)
    },
    closeDetails() {
      this.$emit('clickMore', this.indexDay, false)
    },
    clickSchedule(schedule, isConflict) {
      this.$emit('clickSchedule', schedule, isConflict)
    }
  }
}
</script>
  
<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
</style>
  